html,
body {
  font-family: 'Poppins', serif;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
h4{
  font-family: 'Inter';
}

.nav{
  display: flex;
  justify-content: space-between;
  padding: 10px 2.5rem;
  padding-right: 10px;
}
.platform{
  cursor: pointer;
  background: #fff;
}
.nav_text{
  font-weight: bold;
  font-family: 'Inter', serif;
  cursor: pointer;
  color:#493f3a
}
.nav_text:hover{
  color: #000;
  transition: ease-in-out .3s all;
  scale: 1.01;
}

.nav_logo{
    font-size: 20px;
    background: -webkit-linear-gradient(rgb(145, 0, 0), rgb(82, 5, 5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: 900;
}


.container{
  display: flex;
  flex-wrap: wrap;
  padding:2rem;
  max-width:1980px;
}
.left-container{
  flex:1;
}
.text-body{
  font-size: 50px;
  font-weight: bolder;
  line-height: 10px;
  filter: drop-shadow(10px 10px 80px  rgb(212, 70, 35));  
}
.text-red{
  color: #d53028;
  font-size: 5rem;
  text-shadow:
  1px 1px 0 #fff,
  -1px -1px 0 #fff,  
  1px -1px 0 #fff,
  -1px 1px 0 #fff,
  1px 1px 0 #fff;
}
@media only screen and (max-width: 820px) {
  .left-container{
    flex:1; text-align: center;
  }
  .text-body{
    font-size: 30px ;
  }
  .text-red{
    font-size: 40px ;
  }
  .container{
    display: flex;
    flex-direction: column;
  }
}
.right-container{
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.welcome-img{
  filter: drop-shadow(10px 10px 50px rgb(213, 176, 90));  
}
.text-white{
  color: #fff;
  text-shadow:
        1px 1px 0 #d53028,
        -1px -1px 0 #d53028,  
        1px -1px 0 #d53028,
        -1px 1px 0 #d53028,
        1px 1px 0 #d53028;
}

.text-description{
  padding: 30px 30px;
  font-size: 18px;
  line-height: 25px;
  font-family: 'Inter', sans-serif;

}


.info-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid #999;
  max-width: 500px;
}
.btn{
  padding:10px 20px;
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;
  font-family: 'Inter',sans-serif;
  color: #000;
  font-weight: bold;

}
.btn-active{
  color: #fff;
  font-weight: bold;
  background-color: #040303;
}
.footer{
  min-height: 100px;
  background: #eee;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:10
}